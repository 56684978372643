export async function wait(timeout = 1000){
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export function debounce(func, timeout = 100) {
  let timer = undefined
  return function(event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func,timeout,event)
  }
}

export class Later {
  constructor(interval, handler = undefined) {
    this.handler = handler
    this.interval = interval
    this.timeout_id = undefined
    this.interval_id = undefined
  }

  once(handler) {
    this.abort()
    this.timeout_id = setTimeout(() => (handler || this.handler)(), this.interval)
  }

  repeat(handler = undefined) {
    this.abort()
    this.interval_id = setInterval(() => {
      const res = (handler || this.handler)()
      if (res === false) this.abort()
    }, this.interval)
  }

  abort() {
    if (this.timeout_id) clearTimeout(this.timeout_id)
    if (this.interval_id) clearInterval(this.interval_id)
    this.timeout_id = undefined
    this.interval_id = undefined
  }
}
