import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f299cc3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "spinner",
  "element-loading-background": "transparent"
}
const _hoisted_2 = {
  key: 0,
  class: "text"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader", {inline: $props.inline}])
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */), [
      [_directive_loading, true]
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}