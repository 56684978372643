import * as $utils from 'utils';

export default {
  beforeMount(el, binding) {
    let resizeHandler = (entries) => binding.value(entries, el)
    resizeHandler = binding.arg ? $utils.timers.debounce(resizeHandler, ++binding.arg) : resizeHandler
    el.__resize_observer = new ResizeObserver(resizeHandler)
    el.__resize_observer.observe(el)
  },
  mounted(el) {
    el.dispatchEvent(new Event('resize'))
  },
  unmounted(el) {
    el.__resize_observer.disconnect()
  }
}