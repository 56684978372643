export function url(value, add_proto) {
  try {
    if (add_proto) {
      if (value.indexOf('://') == -1) {
        value = `https://${value}`
      }
    }
    let parsed = new URL(value)
    if (!['http:', 'https:'].includes(parsed.protocol)) {
      return undefined
    }
    return parsed
  }
  catch (err) {
    return false
  }
}
