import {API_FILES} from 'consts/api'
import BaseStore from './base-store'
import StoreProxy from './store_proxy'
import $images, {is_image} from './images.js'


import * as $utils from 'utils';
export class FileUpload extends BaseStore {
  constructor(file, params, resolve) {
    super({
      id: crypto.randomUUID(),
      file: file,
      params: params,
      resolve: resolve,
      error: undefined,
      result: undefined,
      bytes_loaded: 0,
      aborted: false,
    })
  }

  abort() {
    if (this._state.xhr) {
      this._state.xhr.abort()
    }
  }

  get active () {
    if (this._state.error || this._state.result || this._state.aborted) {
      return false
    }
    return true
  }

  async start() {
    this.abort()

    this._state.error = undefined
    this._state.result = undefined
    this._state.bytes_loaded = 0
    this._state.aborted = false
    this._state.xhr = new XMLHttpRequest()
    
    const xhr = this._state.xhr
    xhr.open('PUT', API_FILES, true)

    const auth = await $utils.rest.auth_headers()
    for (const key in auth) {
      xhr.setRequestHeader(key, auth[key])
    }

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('X-Filename', this._state.file.name)
    xhr.setRequestHeader('X-File-Type', this._state.params.type)
    xhr.setRequestHeader('Content-Type', this._state.file.type)

    if (this._state.params.tags) {
      xhr.setRequestHeader('X-File-Tags', this._state.params.tags)
    }

    xhr.upload.addEventListener('abort', () => {
      this._state.aborted = true
    })

    xhr.upload.addEventListener('error', () => {
      this._state.error = new Error(`Error uploading file. Status: ${xhr.status}, response: ${xhr.responseText}'`)
    })

    xhr.upload.addEventListener('timeout', () => {
      this._state.error = new Error('File upload timed out')
    })

    xhr.upload.addEventListener('progress', (ev) => {
      this._state.bytes_loaded = ev.loaded
    })

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            this._state.result = JSON.parse(xhr.responseText)
            if (this._state.resolve) this.resolve(this._state.result, this)
          }
          catch(err) {
            this._state.error = err
          }
        }
        else {
          this._state.error = new Error(`Error uploading file. Status: ${xhr.status}, response: ${xhr.responseText}'`)          
        }
      }
    }

    xhr.send(this._state.file)
  }
}

class File extends BaseStore{
  constructor(file) {
    // TODO: GAI-211 sort tags alphabetically, also in doLoad
    //       we do not do it now cause this can take a lot of
    //       time when we have a lot of files until real
    //       pagination is implemented
    super({
      id: undefined,
      user_id: undefined,
      s3_key: undefined,
      name: undefined,
      ext: undefined,
      tags: [],
      type: undefined,
      size: undefined,
      status: undefined,
      weaviate_id: undefined,
      created_at: undefined,
      removing: false,
      ...file
    }, 
    {
      schema_version: 1
    })

    if (this.is_image) {
      $images.track(this._state)
    }
  }

  get is_image () {
    return is_image(this._state.type)
  }
  
  get failed() {
    return this._state.status === 'failed-processing'
  }

  get processing() {
    if (this._state.type === 'document' || this._state.type === 'brand-definition') {
      return this._state.status === 'uploaded' || this._state.status == 'processing'
    }
    return false
  }

  async doLoad(rest, params) {
    const fdata = await rest.get(`${API_FILES}/${this.id}`)
    $utils.object.proxy_assign(this, fdata)

    if (this.is_image) {
      $images.track(this._state)
    }
  }
}

class Files extends BaseStore {
  // File store can 'view' files by type & tag
  constructor(type, tag = undefined) {
    super({
      files: [],
      uploads: [],
      watchers: [],
      filtered: false,
      total: 0
    })

    this.type = type
    this.tag = tag
    this.watch = new $utils.timers.Later(5000)
  }

  get view() {
    const result = {type: this.type}
    if (this.tag) result['tags'] = [this.tag]
    return result
  }

  async doAbort() {
    this._state.files.forEach(file => file.abortAll())
  }

  async doLoad(rest, params) {
    const {total} = await rest.get(`${API_FILES}/stats`, this.view)
    this._state.total = total

    // TODO: GAI-211 implement real pagination
    this._state.filtered = Object.keys(params).length > 0    
    // TODO: check, if params have save keys as store view then combine, not override
    const files = await rest.get(API_FILES, {...params, ...this.view, limit: 500})

    if (this._reload_id) {
      clearInterval(this._watcher)
      this._watcher = undefined
    }

    if (!files.length) {
      this._state.files = []  
      return
    }

    this._state.files = []
    files.forEach(fdata => this._state.files.push(new File(fdata)))
    this.watch.repeat(() => {
      this._state.files.forEach(file => {if (file.processing) file.load()})
      return this._state.files.find(file => file.processing) != undefined
    })
  }

  async doSave(rest, changed) {
  }

  deleteUpload(id) {
    const ind = this._state.uploads.findIndex(el => el.id === id)
    this._state.uploads[ind].abort()
    this._state.uploads.splice(ind, 1)
  }

  upload(files, {resolve} = {resolve: undefined}) {
    // TODO: support multiple files upload
    const upload = new FileUpload(files[0], this.view, (result, upload) => {
      // TODO: do not reload the store, just add a file
      this.load()
      if (is_image(this.type)) $images.track(result)
      if (resolve) resolve(result, upload)
    })
    this._state.uploads.push(upload)
    upload.start()
  }

  async remove(file_id) {
    let idx = this._state.files.findIndex(file => file.id === file_id)
    this._state.files[idx].removing = true

    await $utils.rest.remove(`${API_FILES}/${file_id}`)
    idx = this._state.files.findIndex(file => file.id === file_id)
    const file = this._state.files.splice(idx, 1)[0]
    
    if (file.is_image) {
      $images.untrack(file_id)
    }
  }
}

//
// Just get file meta from server
// File object won't belong to any store
//
export async function get_file (file_id) {
  const json = await $utils.rest.get(`${API_FILES}/${file_id}`)
  return new File(json)
}

export async function downloadFile(file_id) {
  const file = await get_file(file_id)
  await $utils.downloadFile(file.url)
}

//
// Upload file not tracking progress
// File object won't belong to any store
// Unable to track progress, use for small files only
//
export async function upload_file(file, {type}) {
  // fetch doesn't allow to track progress
  const auth_headers = await $utils.rest.auth_headers()
  const resp = await fetch(API_FILES, {
    method: 'PUT',
    headers: {
      ...auth_headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-Filename': file.name,
      'X-File-Type': type
    },
    body: file
  })

  if (resp.status < 200 || resp.status > 299) {
    throw new Error(resp.statusText)
  }

  const json = await resp.json()
  return new File(json)
}

//
// Global stores
//
const stores = {}

export function create_store(type, tag = undefined) {
  return new StoreProxy(new Files(type, tag), {array_prop: 'files'})
}

export function get_store(type) {
  if (!stores[type]) stores[type] = create_store(type)
  return stores[type]
}

const documents = get_store('document')
export default documents
