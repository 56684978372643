
import rangy from 'rangy'
import 'rangy/lib/rangy-classapplier'

const VueRangy = {
  install (app, options) {
    // eslint-disable-next-line no-prototype-builtins
    if (options.hasOwnProperty('init') && options.init) {
      rangy.init()
    }

    app.config.$rangy = rangy
  }
}

export default VueRangy