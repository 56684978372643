export function copyText(text) {
  const textArea = document.createElement('textarea')
  textArea.style.position = 'fixed'
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    return document.execCommand('copy')
  }
  finally {
    document.body.removeChild(textArea)
  }
}

export function downloadFile(url) {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('sandbox', 'allow-downloads allow-scripts')
  iframe.setAttribute('style', 'display: none')
  iframe.src = url
  document.body.appendChild(iframe)
}
