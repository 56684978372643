export const FIREBASE_PUBLIC_KEY = 'BM1oVdrVgUycIdJlk6uV84jfmVR8WvRhV-myG4ah-ELvDmjD9PL_5wkrMwf9hsOtaCq-YDKb1A_Yb9T5TFIfrXs'
export const NARRATION_TITLE = 'What is your preferred narrator voice?'
export const MUSIC_TITLE = 'What is your preferred background music?'

export const LAYOUT_KEYS = {
  TYPING_FRAMES: 'typing-frames',
  TIKTOK_DEFAULT: 'tiktok-default',
  BRANDED_NEWSLETTER: 'branded-newsletter',
  TIKTOK_MIXED: 'tiktok-mixed',
  SKN_SIMPLE: 'skn-fixed',
  IBI_FIRST: 'ibi-first',
  IBI_SECOND: 'ibi-second'
}

export const BASE_FRAMES = [
  {
    title: 'Article URL',
    id: 'article',
  },
  {
    title: 'Layout',
    id: 'layout',
  },
  {
    title: 'Wizard',
    id: 'wizard'
  },
  {
    title: 'Editor',
    id: 'editor'
  }
]

export const LAYOUTS = {
  [LAYOUT_KEYS.TIKTOK_MIXED]: {
    title: 'Advanced',
    value: LAYOUT_KEYS.TIKTOK_MIXED,
    preview: '/assets/tiktok-mixed.png',
    default_colors: ['#ffffff', '#F7C04A', '#3F497F', '#539165'],
    frames: [
      {
        title: 'Focus',
        id: 'focus',
      },
      {
        title: 'Color',
        id: 'color',
        grow: true
      },
      {
        title: 'Writing style',
        id: 'writing_style',
        grow: true
      },
      {
        title: 'Reader skill',
        id: 'reader_level',
        grow: true
      },
      {
        title: 'Narration',
        id: 'voice',
        grow: true
      },
      {
        title: 'Music',
        id: 'music',
        grow: true
      }
    ],
  },
  [LAYOUT_KEYS.BRANDED_NEWSLETTER]: {
    title: 'Branded',
    value: LAYOUT_KEYS.BRANDED_NEWSLETTER,
    preview: '/assets/branded-newsletter.png',
    default_colors: ['#ffffff', '#F7C04A', '#3F497F', '#539165'],
    frames: [
      {
        title: 'Focus',
        id: 'focus',
      },
      {
        title: 'Color',
        id: 'color',
        grow: true
      },
      {
        title: 'Writing style',
        id: 'writing_style',
        grow: true
      },
      {
        title: 'Reader skill',
        id: 'reader_level',
        grow: true
      },
      {
        title: 'Narration',
        id: 'voice',
        grow: true
      },
      {
        title: 'Music',
        id: 'music',
        grow: true
      }
    ],
  },
  [LAYOUT_KEYS.TIKTOK_DEFAULT]: {
    title: 'TikTok',
    value: LAYOUT_KEYS.TIKTOK_DEFAULT,
    preview: '/assets/tiktok-default.png',
    default_colors: ['#ffffff', '#005DFF'],
    frames: [
      {
        title: 'Focus',
        id: 'focus',
      },
      {
        title: 'Logo',
        id: 'logo'
      },
      {
        title: 'Color',
        id: 'color'
      },
      {
        title: 'Writing style',
        id: 'writing_style',
        grow: true
      },
      {
        title: 'Reader skill',
        id: 'reader_level',
        grow: true
      },
      {
        title: 'Narration',
        id: 'voice',
        grow: true
      },
      {
        title: 'Music',
        id: 'music',
        grow: true
      }],
  },
  [LAYOUT_KEYS.TYPING_FRAMES]: {
    title: 'Newsletter',
    value: LAYOUT_KEYS.TYPING_FRAMES,
    preview: '/assets/typing-frames.png',
    default_colors: ['#ffffff'],
    frames: [
      {
        title: 'Focus',
        id: 'focus',
      },
      {
        title: 'Color',
        id: 'color'
      },
      {
        title: 'Writing style',
        id: 'writing_style'
      },
      {
        title: 'Reader skill',
        id: 'reader_level'
      },
      {
        title: 'Narration',
        id: 'voice'
      },
      {
        title: 'Music',
        id: 'music'
      }
    ],
  },
}

export const READER_LEVELS = [
  {
    value: 'amateur',
    title: 'Amateur',
    text: 'An amateur can understand this',
    img: '/assets/customer.svg'
  },
  {
    value: 'novice',
    title: 'Novice',
    text: 'Yo! Easy for beginners to get',
    img: '/assets/teenager_male.svg'
  },
  {
    value: 'professional',
    title: 'Professional',
    text: 'Technical, for professionals to grasp',
    img: '/assets/businessman.svg'
  }
]

export const WRITING_STYLES = [
  {
    title: 'Casual',
    value: 'casual',
    text: 'This is written in a casual style',
    img: '/assets/customer.svg'
  },
  {
    title: 'Friendly',
    value: 'friendly',
    text: 'Hey! Written in a friendly style here :)',
    img: '/assets/teenager_male.svg'
  },
  {
    title: 'Formal',
    value: 'formal',
    text: 'Greetings. This is written in a formal manner',
    img: '/assets/businessman.svg'
  }
]

export const MUSIC = [
  {
    title: 'Casual',
    value: 'casual',
    src: '/assets/classic-music.svg'
  },
  {
    title: 'Quiet',
    value: 'quiet',
    src: '/assets/lullaby.svg'
  },
  {
    title: 'Happy',
    value: 'happy',
    src: '/assets/maracas.svg'
  },
  {
    title: 'None',
    value: 'none',
    src: '/assets/mute.svg'
  }
]

export const VOICE = [
  {
    title: 'Male',
    value: 'male',
    src: '/assets/man.svg'
  },
  {
    title: 'Female',
    value: 'female',
    src: '/assets/woman.svg'
  },
  {
    title: 'None',
    value: 'none',
    src: '/assets/mute.svg'
  }
]

export const PREVIEW_MODES = [
  {
    title: 'Content',
    value: 'content',
    semanticIcon: 'list alternate outline icon'
  },
  {
    title: 'Sound',
    value: 'sound',
    semanticIcon: 'music icon'

  },
  {
    title: 'Options',
    value: 'options',
    semanticIcon: 'cog icon'
  }
]

export const PREVIEW_CONTENT_MODES = [
  {
    title: 'text',
    value: 'text',
    semanticIcon: 'pencil alternate icon'
  },
  {
    title: 'img',
    value: 'img',
    semanticIcon: 'file image outline icon'
  }
]

export const ALLOWED_NO_SHARE_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/wps-office.docx',
  'text/html',
  'application/pdf',
  'text/plain',
]

export const ALLOWED_FILE_TYPES = [
  ...ALLOWED_NO_SHARE_FILE_TYPES,
  'application/rtf',
  'text/rtf',
]
