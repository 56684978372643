//
// This is a global store that helps to resolve IDs to URLs
// for all the encrypted images that user uploads to server
//
import BaseStore from './base-store.js'
import StoreProxy from './store_proxy.js'
import {API_FILES} from 'consts/api.js'

const MAX_IMAGES = 1000

export function is_image(type) {
  return type === 'avatar'
}

export class Image extends BaseStore {
  constructor(values) {
    super({
      id: values?.id || undefined,
      url: values?.url || undefined,
      name: values?.name || undefined,
      size: values?.size || undefined,
      error: undefined
    })
  }

  async doLoad(rest, params) {
    try {
      const raw = await rest.get(`${API_FILES}/${this._state.id}`)
      Object.assign(this._state, {url: raw.url, name: raw.name, size: raw.size})
    }
    catch(err) {
      this.error = err
    }
  }
}

export class Images extends BaseStore {
  constructor() {
    super({
      images: {},
      counter: []
    })
  }

  get(id) {
    return this._safe_append({id})
  }

  track (raw) {
    if (!raw.id) throw Error(`Unable to track image withoud id ${raw.id}`)   
    return this._safe_append(raw)
  }

  _safe_append(raw) {
    //
    // If there is no image with this id then create
    // otherwise get the current one
    //
    let image = this._state.images[raw.id]

    if (!image) {
      image = new Image(raw)
      this._state.images[raw.id] = image
      this._state.counter.push(raw.id)

      while (this._state.counter.length > MAX_IMAGES) {
        const id = this._state.counter.shift()
        delete this._state.images[id]
      }
    }

    // if url provided assing it to image
    if (raw.url) image.url = raw.url

    // if there is no url at this point load from server
    // but only if we haven't tried before
    if (!image.url && !image.error) image.load()

    return image
  }
}

const images = new StoreProxy(new Images(), {array_prop: 'images'})
export default images
