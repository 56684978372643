import {reactive} from 'vue'
import {errors, rest} from 'utils'
import router from 'router'
import {API_PROFILE, API_SETTINGS} from 'consts/api'
import {LAYOUT_KEYS} from 'consts/layouts/base'
import {get_file} from 'stores/files'
import {signOut} from 'aws-amplify/auth'


import * as $utils from 'utils';
function defaultState() {
  let auto_download = JSON.parse(localStorage.getItem('auto_download') || 'true')
  let layout = localStorage.getItem('layout')
  const keys = {...LAYOUT_KEYS}
  delete keys.SKN_SIMPLE
  if (!Object.values(keys).includes(layout)) {
    layout = LAYOUT_KEYS.TIKTOK_DEFAULT
  }

  const state = {
    loading: false,
    error: undefined,
    focus: undefined,
    layout,
    auto_download,
    videoUrl: null,
    debug: true,
    article_src: '',
    profile: undefined,
    user_layouts: undefined,
    preview_id: undefined,
    file: undefined,
    nav_closed: localStorage.getItem('nav_closed') === 'true',
  }
  return state
}

function makeState() {
  const state = reactive({
    ...defaultState()
  })
  return state
}

export const store = {
  state: makeState(),

  get profile() {
    return this.state.profile
  },

  set profile(userData) {
    this.state.profile = {...this.state.profile, ...userData}
  },

  get user_layouts() {
    return this.state.user_layouts
  },

  goToLogin() {
    router.push({
      name: 'login'
    })
  },

  goToWelcome() {
    return router.push({
      name: 'welcome'
    })
  },
  
  goToBrand() {
    return router.push({
      name: 'brand'
    })
  },
  
  goToProfile() {
    router.push({
      name: 'profile'
    })
  },
  
  goToOnboarding() {
    router.push({
      name: 'onboarding',
    })
  },
  
  goToPreview(taskId) {
    router.push({
      name: 'preview',
      query: {id: taskId}
    })
  },
  
  goToVideo(taskId) {
    router.push({
      name: 'video',
      query: {id: taskId}
    })
  },

  async loadProfile() {
    const prof_data = await rest.get(API_PROFILE)
    if (prof_data.logo_id) {
      const logo_data = await get_file(prof_data.logo_id)
      prof_data.logo = logo_data.url
    }
    this.profile = prof_data
  },

  async loadLayouts() {
    const settings_data = await rest.get(API_SETTINGS, {pattern: '^template_'})
    const settings = settings_data.reduce((state, item) => ({...state, [item.key]: item.value}), {})
    this.setParam('user_layouts', settings)
  },

  _saveSettings(...settings) {
    return rest.put(API_SETTINGS, settings)
  },

  async removeSettings(...settings) {
    const res = await rest.remove(API_SETTINGS, settings)
    if (res.result && this.state.user_layouts) {
      settings.forEach((item) => {
        delete this.state.user_layouts[item]
      })
    }
  },

  async getFocusByArticle() {
    try {
      const hash = $utils.hash.md5(this.article_src)
      const res = await rest.get(`${API_SETTINGS}/focus_${hash}`)
      if (!res.key || !res.value?.focus) throw new errors.InvalidResponse()
      return res
    } catch (err) {
      if (err instanceof errors.InvalidResponse || err.status_code !== 404) return this.setError(err)
      console.warn('No focus associated with the URL')
    }
  },

  async saveFocus(focus) {
    return this._saveSettings({
      key: `focus_${$utils.hash.md5(this.article_src)}`,
      value: {focus}
    })
  },
  async saveLayout(title, settings) {
    const body = {
      key: `template_${$utils.hash.md5(title + this.state.layout)}`,
      value: {
        ...settings,
        title,
        layout: this.state.layout,
        focus: this.state.focus
      }
    }
    const res = await this._saveSettings(body)
    if (res.result && this.state.user_layouts) {
      Object.assign(this.state.user_layouts, {[body.key]: body.value})
    }
  },

  _calcFocusKey(asrc) {
    return 'last_focus'
  },

  get article_src() {
    return this.state.article_src
  },

  set article_src(value) {
    this.state.article_src = value
  },

  set file(value) {
    this.state.file = value
  },

  get file() {
    return this.state.file
  },

  get preview_id() {
    return this.state.preview_id
  },

  set preview_id(value) {
    this.state.preview_id = value
  },

  get focus() {
    if (this.state.focus) return this.state.focus
    const focuses = JSON.parse(localStorage.getItem('focus')) || {}
    const key = this._calcFocusKey(this.state.article_src)
    this.state.focus = focuses[key] || ''
    return this.state.focus
  },

  set focus(focus) {
    this.state.focus = focus
    const focuses = JSON.parse(localStorage.getItem('focus')) || {}
    const key = this._calcFocusKey(this.state.article_src)
    if (focus) focuses[key] = focus
    else delete focuses[key]
    localStorage.setItem('focus', JSON.stringify(focuses))
  },

  set layout(layout = LAYOUT_KEYS.TIKTOK_DEFAULT) {
    localStorage.setItem('layout', layout)
    this.state.layout = layout
  },

  get layout() {
    return this.state.layout
  },

  get nav_closed() {
    return this.state.nav_closed
  },

  set nav_closed(value) {
    localStorage.setItem('nav_closed', value)
    this.state.nav_closed = value
  },

  setVideoUrl(url) {
    this.state.videoUrl = url
  },

  setError(error) {
    if (error instanceof errors.PromiseCancelled) {
      return console.warn(error.message)
    }
    
    if (error instanceof errors.Unauthorized) {
      console.log(error)
      return this.logout()
    }
    
    this.state.error = {error, reload: true}
    console.error(error)
  },

  clearError() {
    if (this.state.error) {
      this.state.error = undefined
      window.location.reload()
    }
  },

  clearArticle() {
    this.article_src = null
    this.file = null
  },

  getParam(key) {
    return this.state[key]
  },

  setParam(key, value) {
    this.state[key] = value
  },

  //
  // Refactored
  //
  async start() {
    this.state.loading = true     
    await this.loadLayouts()
    await this.loadProfile()
    this.state.loading = false
  },

  async logout () {
    await signOut()
    this.state.loading = false
    this.goToLogin()
  },

  //
  // TODO: Change getters to BaseStore?
  //
  get loading () {
    return this.state.loading
  },

  get error () {
    return this.state.error
  }
}

export default store
