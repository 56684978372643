/* unplugin-vue-components disabled */
import Modal from './modal'

export default {
  components: {
    Modal,
  },
  props: {
    error: {
      default: undefined,
      type: [String, Object]
    },
    title: {
      type: String,
      default: 'Error occurred'
    },
  },
  emits: ['copy'],
  computed: {
    errtext() {
      let result = undefined

      if (this.error.error.text) {
        result = this.error.error.text
      }
      else if (this.error.error instanceof Error) {
        result = this.error.error.message
      }

      return result ? result : 'Something went wrong. Please copy error message and contact support.'
    },

    errfull() {
      let formatError = (error) =>  {
        if (typeof error === 'string') {
          return error
        }
        
        if (error instanceof Error) {
          return error.stack
        }

        error = Object.assign({}, error)
        return $utils.format.json(error)
      }
      return formatError(this.error.error)
    }
  },

  mounted () {
    console.log('error')
    this.$refs.modal.open()
  },

  methods: {
    onCopyError() {
      $utils.copyText(this.errfull)
    },
 
    onRestart() {
      this.close()
    },

    close () {
      this.$refs.modal.close()
      this.$store.clearError()
    }
  }
}
