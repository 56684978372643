

export default {
  props: {
    max_width: {
      type: String,
      default: '50%'
    },
    min_width: {
      type: String,
      default: '30em'
    },
    segment: {
      type: String,
      default: undefined
    },
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    content_style() {
      let style = {
        'background-color': '#fff',
      }
      if (this.max_width) {
        style['max-width'] = this.max_width
      }
      if (this.min_width) {
        style['min-width'] = this.min_width
      }
      return style
    },

    id() {
      return Math.random()
    }
  },

  methods: {
    close () {
      this.show = false
      document.getElementById('application').style.opacity = 1
    },

    open () {
      this.show = true
      document.getElementById('application').style.opacity = 0.3
    },
  }
}
