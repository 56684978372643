import * as $utils from 'utils';

export default {
  beforeMount(el, binding) {
    const handler = (ev) => binding.value(ev, el)
    el.handler = binding.arg ? $utils.timers.debounce(handler, ++binding.arg) : handler
    window.addEventListener('resize', el.handler)
  },
  unmounted(el) {
    window.removeEventListener('resize', el.handler)
  },
}