export class PromiseCancelled extends Error {
}

export class Unauthorized extends Error {
  constructor(url) {
    super(`Unauthorized at ${url}`)
  }
}

export class InvalidResponse extends Error {
  constructor() {
    super('Invalid response received')
  }
}