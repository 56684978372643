import {API_STATUS, API_TASK} from 'consts/api'
import {PromiseCancelled} from './errors.js'
import rest from './rest.js'

export class Tasks {
  constructor() {
    this._tasks = []
  }

  async start(name, args = {}, action) {
    return await rest.post(`${API_TASK}/${name}`, args)
  }

  async get(task_id, options) {
    return await rest.get(`${API_TASK}/${task_id}`, undefined, options)
  }

  async exists(task_id) {
    return this.get(task_id, {throw_404: false}) !== undefined
  }

  async getArg(task_id) {
    const {task_arg} = await this.get(task_id)
    return JSON.parse(task_arg)
  }

  cancelOne(task_id) {
    const task = this._tasks.find(x => x.task_id === task_id)
    if (task) {
      this._killTask(task)
    }
  }

  cancelAll() {
    for (const task of this._tasks) {
      this._killTask(task)
    }
    this._tasks = []
  }

  _killTask(task){
    clearTimeout(task.timeout_id)
    task.reject(new PromiseCancelled())
  }

  async wait(task_id, timeout = 1000, inermediate_cback=undefined) {
    const data = await this.get(task_id)
    
    switch (data.status) {
    case API_STATUS.COMPLETED: return data.result
    case API_STATUS.FAILED: throw data.error
    }

    if (inermediate_cback) {
      inermediate_cback(data)
    }

    return new Promise((resolve, reject) => {      
      const timeout_id = setTimeout(() => {
        this._tasks = this._tasks.filter(task => task.timeout_id != timeout_id)
        resolve(this.wait(task_id, timeout, inermediate_cback))
      }, timeout)
      this._tasks.push({reject, timeout_id, task_id})
    })
  }

  async waitTask(task_id, timeout = 1000) {
    const task = await this.get(task_id)

    switch (task.status) {
    case API_STATUS.COMPLETED:
    case API_STATUS.FAILED:
      return task
    }

    return new Promise((resolve, reject) => {      
      const timeout_id = setTimeout(() => {
        this._tasks = this._tasks.filter(task => task.timeout_id != timeout_id)
        resolve(this.waitTask(task_id, timeout))
      }, timeout)
      this._tasks.push({reject, timeout_id})
    })
  }
}

const tasks = new Tasks()
export default tasks
