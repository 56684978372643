export default {
  created() {
    const vread_from = this.$attrs['vread-from']
    if (vread_from && Object.prototype.hasOwnProperty.call(this, vread_from)) {
      // track changes
      this.$watch(vread_from, (nval) => {
        this.$emit(`update:${vread_from}`, nval)
      })
      // pop up an initial val to the reader
      this.$emit(`update:${vread_from}`, this[vread_from])
    }
  }
}
