import {API_SETTINGS} from 'consts/api'
import $rest from './rest.js'

export class Settings {
  constructor() {
  }

  async get(name, defval) {
    try {
      const result = await $rest.get(`${API_SETTINGS}/${name}`)
      return result.value
    }
    catch(err) {
      if (err.status_code === 404) return defval
      throw err
    }
  }

  async put(name, value) {
    return await $rest.put(API_SETTINGS, [{key: name, value: value}])
  }

  async remove(name) {
    return await $rest.remove(API_SETTINGS, [name])
  }
}

const settings = new Settings()
export default settings