import * as array from './array.js'
import * as object from './object.js'

export function same(a, b) {
  if (array.isArray(a) && array.isArray(b)) return array.same(a, b)
  if (object.isObject(a) && object.isObject(b)) return object.same(a, b)
  throw new Error(`Can't compare ${typeof a} and ${typeof b}`)
}

export function daysRange(cur, range){
  let [start_ms, end_ms] = range.map(el => el && new Date(el).setHours(0, 0, 0, 0))
  const curr_ms = new Date(cur).setHours(0, 0, 0, 0)
  if (!end_ms) end_ms = start_ms
  return (curr_ms >= start_ms && curr_ms <= end_ms)
}
