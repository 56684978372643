import prettyBytes from 'pretty-bytes'

export function bytes(bytes) {
  return prettyBytes(+bytes, {binary: true})
}

export function date(date) {
  return new Date(date).toLocaleDateString()
}

export function datetime(date) {
  return new Date(date).toLocaleString()
}

export function json(obj) {
  let res = JSON.stringify(obj, null, 2)
  return res == '{}' ? obj.toString() : res
}
