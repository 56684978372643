function StoreProxy (target, options = {}) {
  return new Proxy(target, { 
    get (target, prop, receiver) {
      // if array prop is present we simulate array access by index 
      //    $store[idx] --> $store._state[options.array_prop][idx]
      //    $store.length -> $store._state[options.array_prop].length
      if (options.array_prop) {
        if (prop === 'length') {
          return Reflect.get(target._state[options.array_prop], length, receiver)
        }
        if (typeof prop === 'string') {  
          const idx = parseInt(prop)
          if (!isNaN(idx)) {
            return Reflect.get(target._state[options.array_prop], idx, receiver)
          }
        }
      }
      return Reflect.get(target, prop, receiver)
    },
    set(target, prop, value, receiver) {
      // if array prop is present we simulate array access by index 
      //    $store[idx] = val --> $store._state[options.array_prop][idx] = val
      if (options.array_prop) {
        const idx = parseInt(prop)
        if (!isNaN(idx)) {
          return Reflect.set(target._state[options.array_prop], idx, value, receiver)
        }
      }
      return Reflect.set(target, prop, value, receiver)
    }
  })
}

export default StoreProxy
