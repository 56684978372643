export default {
  mounted(el, binding) {
    if (binding.value) {
      el.style.visibility = 'hidden'
    }
  },
  updated(el, binding) {
    if (binding.value) {
      el.style.visibility = 'hidden'
    } else {
      el.style.visibility = 'visible'
    }
  },
}