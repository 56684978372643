/* eslint-disable no-prototype-builtins */
import {PromiseCancelled, Unauthorized} from './errors.js'
import {fetchAuthSession} from 'aws-amplify/auth'

function objectToQueryString(obj) {
  return (Object.keys(obj))
    .map(key => key + '=' + obj[key])
    .join('&')
}

export class Rest {
  constructor() {
    this.abort_controller = new AbortController()
    this.get = this._request('GET')
    this.put = this._request('PUT')
    this.post = this._request('POST')
    this.patch = this._request('PATCH')
    this.remove = this._request('DELETE')
  }

  abortAll () {
    this.abort_controller.abort()
  }

  async auth_headers () {
    const session = await fetchAuthSession()
    const id_token = session.tokens.idToken.toString()
    return {
      'Authorization': `Bearer ${id_token}`
    }
  }

  _request (method) {
    return async (baseUrl, params, config = {}) => {
      config = {throw_404: true, ...config}    
      const auth_headers = await this.auth_headers()
      const options = {
        method,
        signal: config.abort,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          ...auth_headers,
          ...config.headers
        }
      }

      const is_filedata = config?.hasOwnProperty('fileData')
      const is_formdata = (
        options.headers['Content-Type'] === 'application/x-www-form-urlencoded' ||
        is_filedata
      )

      if (!is_filedata) {  // for uploading files Content-Type should be set automatically by the browser
        options.headers['Content-Type'] = is_formdata ? 'application/x-www-form-urlencoded' : 'application/json'
      }

      let url = baseUrl
      if (params) {
        switch (method) {
        case 'GET': url += '?' + objectToQueryString(params); break
        default: options.body = is_formdata ? params : JSON.stringify(params)
        }
      }
      
      try {
        const res = await fetch(url, options)
        
        if (res.status === 401) {
          throw new Unauthorized(url)
        }

        if (res.status === 404 && !config.throw_404) {
          return undefined
        }
        
        if (res.status == 204) {
          return {}
        }
        
        const text = await res.text()
        let json = undefined

        try {
          json = JSON.parse(text)
        }
        catch(err) {
          throw new Error(`Failed to parse server response. ${err.toString()}`)
        }
        
        if (res.ok) return json
        throw {...json, status_code: res.status}
      } 
      catch (err) {
        if (err.name === 'AbortError') throw new PromiseCancelled('Request cancelled by user action')
        throw err
      }
    }
  }
}

const rest = new Rest()
export default rest
