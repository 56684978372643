
import ErrorModal from 'shared/components/error-modal.vue'
import $global from 'stores/global'
import {getCurrentUser} from '@aws-amplify/auth'
import {Hub} from '@aws-amplify/core'

export default {
  components: {
    ErrorModal,
  },
  data() {
    return {
      signed_in: false
    }
  },
  computed: {
    error () {
      return $global.error
    },
    loading () {
      return $global.loading
    }
  }, 
  watch: {
    signed_in: async function(signed_in) {
      if (signed_in) {
        await $global.start()
        if (this.$route.path === '/login') {
          const go_to = this.$route.query.redirect || '/'
          this.$router.push(go_to)
        }
      }
      else {
        this.$router.push({name: 'login'})
      }
    }
  },
  async created () {
    Hub.listen('auth', async data => {
      switch(data.payload.event) {
      case 'signedIn': this.signed_in = true; break
      case 'signedOut': this.signed_in = false; break
      }
    })

    try {
      await getCurrentUser()
      this.signed_in = true
    }
    catch(err) {
      // if there is no current user we simply ignore
      // router would detect this as well and redirect to login
    }
  }
}
