export default {
  beforeMount(el, binding, vnode) {
    el.clickOutsideEvent = (evt) => {
      if (!(el === evt.target || el.contains(evt.target))) {
        binding.value(evt, el)
      }
    }
    // Wait 1 frame otherwise a potential click that mounted the element will immediately trigger a click-outside event:
    window.requestAnimationFrame(() => { 
      document.addEventListener('mousedown', el.clickOutsideEvent, {capture: true}) 
    })
  },
  unmounted(el) {
    document.removeEventListener('mousedown', el.clickOutsideEvent)
  },
}