import 'fomantic-ui-less/semantic.less'
import 'fomantic-ui/dist/semantic'
import 'vue3-carousel/dist/carousel.css'
import './styles/old-global.styl'
import './styles/global.styl'
import './styles/icons.styl'
import {createApp} from 'vue'
import * as DirectivesModule from './directives'
import PrimeVue from 'primevue/config'
import App from './app'
import store from 'stores/global'
import router from 'router'
import {VueRangy} from 'plugins'
import {Amplify} from 'aws-amplify'
import AmplifyVue from '@aws-amplify/ui-vue'


import * as $utils from 'utils';
if (!LOCAL_FRONT) {
  //
  // In non-local mode we force HTTPS
  //
  if (window.location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`)
  }
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_POOL,
      userPoolClientId: COGNITO_CLIENT
    }
  }
})

const VERSION_KEY = 'version'
const VERSION = '0.0.2'

if (localStorage.getItem(VERSION_KEY) !== VERSION) {
  console.log('UI version changed: clearing local storage')
  localStorage.clear()
  localStorage.setItem(VERSION_KEY, VERSION)
}

const vueApp = createApp(App)
const {VReadMixin, ...directives} = DirectivesModule

vueApp.mixin(VReadMixin)
Object.keys(directives).forEach(key => vueApp.directive(key, directives[key]))

vueApp.config.errorHandler = (err, instance, info) => {
  store.setError(err)
}

window.addEventListener('unhandledrejection', ev => {
  ev.preventDefault()
  store.setError(ev.reason)
})

vueApp.config.globalProperties.$store = store
vueApp.config.globalProperties.$state = store.state
vueApp.config.globalProperties.$utils = $utils

vueApp
  .use(PrimeVue, {unstyled: true})
  .use(VueRangy, {init: true})
  .use(AmplifyVue)
  .use(router)
  .mount('body')
