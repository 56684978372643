import {getCurrentUser} from 'aws-amplify/auth'
import {createRouter, createWebHistory} from 'vue-router'
import {INSUFFICIENT_OAUTH_PERMISSIONS, SUCCESS_OAUTH} from 'consts/event'
import {lazyLoad} from 'utils'

const routes = [
  {
    path: '/login',
    component: lazyLoad(() => import('pages/AmplifyLogin')),
    name: 'login',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      requiresAuth: true,
    },
    component: lazyLoad(() => import('pages/logout')),
  },
  {
    path: '/',
    name: 'root',
    component: lazyLoad(() => import('pages/root-layout')),
    meta: {
      requiresAuth: true,
    },
    redirect: {
      name: 'brand'
    },
    children: [ 
      {
        path: '/old',
        name: 'old',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: lazyLoad(() => import('pages/welcome')),
            name: 'welcome'
          },
          {
            path: 'wizard',
            component: lazyLoad(() => import('pages/Onboarding')),
            name: 'onboarding'
          },
          {
            path: 'profile',
            component: lazyLoad(() => import('pages/Profile')),
            name: 'profile'
          },
          {
            path: 'preview',
            component: lazyLoad(() => import('pages/Preview')),
            name: 'preview'
          },
          {
            path: 'video',
            component: lazyLoad(() => import('pages/Video')),
            name: 'video',
            beforeEnter(to, from) {
              to.params.prev_name = from.name
            }
          },
        ]
      },
      {
        path: 'spotlight',
        component: lazyLoad(() => import('pages/spotlight')),
        name: 'spotlight'
      },
      {
        path: '/social',
        redirect: {
          name: 'brand'
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'brand',
            component: lazyLoad(() => import('pages/brand')),
            name: 'brand'
          },
          {
            path: 'profiles',
            component: lazyLoad(() => import('pages/social-profiles')),
            name: 'social-profiles'
          },
          {
            path: 'profile',
            component: lazyLoad(() => import('pages/social-profiles/social-profile')),
            name: 'social-profile'
          },
          {
            path: 'posts',
            component: lazyLoad(() => import('pages/social-posts')),
            name: 'social-posts'
          },
          {
            path: '/post',
            component: lazyLoad(() => import('pages/social-posts/social-post')),
            name: 'social-post'
          }
        ]
      },
      {
        path: '/market',
        component: lazyLoad(() => import('pages/market-research')),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/briefs',
        component: lazyLoad(() => import('pages/product-flow')),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/files',
        component: lazyLoad(() => import('pages/files')),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/settings',
        component: lazyLoad(() => import('pages/settings')),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/projects',
        component: lazyLoad(() => import('pages/product-flow')),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'network-inventory',
            component: lazyLoad(() => import('pages/product-flow')),
            children: [
              {
                path: 'some1',
                component: lazyLoad(() => import('pages/product-flow'))
              },
              {
                path: 'some2',
                component: lazyLoad(() => import('pages/product-flow'))
              }
            ]
          },
          {
            path: 'notifications',
            component: lazyLoad(() => import('pages/product-flow'))
          },
          {
            path: 'analytics',
            component: lazyLoad(() => import('pages/product-flow'))
          },
          {
            path: 'reports',
            component: lazyLoad(() => import('pages/product-flow'))
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: {
      name: 'root'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/success_login') {
    localStorage.setItem('genrate_gtoken', to.query.genrate_gtoken)
    window.opener.dispatchEvent(new CustomEvent(SUCCESS_OAUTH))
    window.close()
  }
  else if (to.path === '/cancelled_login') {
    window.close()
  }
  else if (to.path === '/insufficient_permissions_login') {
    window.opener.dispatchEvent(new CustomEvent(INSUFFICIENT_OAUTH_PERMISSIONS))
    window.close()
  }
  else if (to.path === '/login') {
    getCurrentUser()
      .then(() => next(to.query?.redirect || '/'))
      .catch(() => next())
  }
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    getCurrentUser()
      .then(() => next())
      .catch(() => next({
        name: 'login', 
        query: {redirect: to.path}
      }))
  }
  else {
    return next()
  }
})

export default router
