export const MAX_DIG = 57
// default decimal unicode values
const A_UP = 65
const A_LOW = 97
export const ZERO = 48

// Math sans ES6 unicode values
const DEF_UP = '\u{1D5A0}'.codePointAt() //120224
const DEF_LOW = '\u{1D5BA}'.codePointAt() //120250
export const DEF_ZERO = '\u{1D7E2}'.codePointAt() //120802

const BOLD_UP = '\u{1D5D4}'.codePointAt() //120276
const BOLD_LOW = '\u{1D5EE}'.codePointAt() //120302
export const BOLD_ZERO = '\u{1D7EC}'.codePointAt() //120812

const ITALIC_UP = '\u{1D608}'.codePointAt() //120328
const ITALIC_LOW = '\u{1D622}'.codePointAt() //120354

const BOLD_ITALIC_UP = '\u{1D63C}'.codePointAt() //120380
const BOLD_ITALIC_LOW = '\u{1D656}'.codePointAt() //120406

// diffs between math sans and default
export const DEF_DIF_UP = DEF_UP - A_UP
export const DEF_DIF_LOW = DEF_LOW - A_LOW

export const BOLD_DIF_UP = BOLD_UP - A_UP
export const BOLD_DIF_LOW = BOLD_LOW - A_LOW

export const ITALIC_DIF_UP = ITALIC_UP - A_UP
export const ITALIC_DIF_LOW = ITALIC_LOW - A_LOW

export const BOLD_ITALIC_DIF_UP = BOLD_ITALIC_UP - A_UP
export const BOLD_ITALIC_DIF_LOW = BOLD_ITALIC_LOW - A_LOW
