import {defineAsyncComponent, h} from 'vue'
import Loader from 'controls/loader'

// TODO: need to make lazyLoad to accept component name, not a func
export function lazyLoad(loader, params = {}) {
  const async_component = defineAsyncComponent({
    loader,
    loadingComponent: Loader,
    delay: 0,
    ...params
  })
  return {
    render() {
      return h(async_component)
    }
  }
}
