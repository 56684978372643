import pdfMake from 'pdfmake/build/pdfmake.min'
import htmlToPdfmake from 'html-to-pdfmake'

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  }
}

export async function saveDOM(node, {name = 'pdf-document', styles}) {
  const val = htmlToPdfmake(node.outerHTML)

  let imgidx = 0
  const images = {}
  
  const add_images = (node) => {
    if (node.nodeName === 'IMG') {
      const imgid = `image-${++imgidx}`
      
      images[imgid] = {'url': node.image}
      node.image = imgid

      for (const key in node) {
        if (key.toLowerCase().indexOf('width') !== -1) node['width'] = node[key]
        // setting height would cause image to be deformed if in original image width != height
        // if (key.toLowerCase().indexOf('height') !== -1) node['height'] = node[key]  
      }
    }

    if (node.stack) {
      node.stack.forEach(node => add_images(node))
    }
  }
  val.forEach(node => add_images(node))
  const dd = {
    content:val,
    styles: styles,
    images: images
  }
  pdfMake.createPdf(dd).download(`${name}.pdf`)
}
