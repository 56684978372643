export const DEFAULT = BACKEND_URL
export const DEFAULT_API = `${DEFAULT}/api`
export const API_LOGIN = `${DEFAULT_API}/login`
export const API_PROFILE = `${DEFAULT_API}/users/me`
export const API_ADMIN_USERLIST = `${DEFAULT_API}/users`
export const API_ADMIN_USER_UPDATE = `${DEFAULT_API}/users`
export const API_COMPOSE = `${DEFAULT_API}/tasks/compose`
export const API_TASK = `${DEFAULT_API}/tasks`
export const API_TEXT = `${DEFAULT_API}/tasks/text`
export const API_UPLOAD_DOCUMENT = `${DEFAULT_API}/tasks/upload/document`
export const API_SETTINGS = `${DEFAULT_API}/settings`
export const API_DOCUMENT_STATUS = `${DEFAULT_API}/tasks/check/status`
export const API_AUTHORIZATION_URL = `${DEFAULT_API}/authorize`
export const API_BRANDS =  `${DEFAULT_API}/brands`
export const API_AUDIT_CLIENT =  `${DEFAULT_API}/audit/client`
export const API_AUDIT_LOGS =  `${DEFAULT_API}/audit/events`
export const API_AUDIT_GROUPS =  `${DEFAULT_API}/audit/groups`
export const API_AUDIT_USERS =  `${DEFAULT_API}/audit/users`
export const API_BRANDS_STATS = `${DEFAULT_API}/brands/stats`
export const API_SOCIAL_PROFILES = `${DEFAULT_API}/social-profiles`
export const API_SOCIAL_PROFILES_STATS = `${DEFAULT_API}/social-profiles/stats`
export const API_SOCIAL_POSTS = `${DEFAULT_API}/social-posts`
export const API_SOCIAL_POSTS_STATS = `${API_SOCIAL_POSTS}/stats`
export const API_FILES = `${DEFAULT_API}/files`
export const API_COMPANIES = `${DEFAULT_API}/companies`
export const TASK_AMDOCS_SCRAPE = 'scrape/amdocs'
export const TASK_AMDOCS_SCRAPE_DELETE = 'scrape/amdocs/delete'
export const AUDIT_TOKEN = AUDIT_TOKEN_URL

export const API_STATUS = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  RUNNING: 'running'
}

export const ARTICLE_STATUS = {
  NOT_AVAILABLE: 0,
  AVAILABLE: 1,
  SHORT_TEXT: 2,
}

export const GENRATE_BRAND = {
  'tones': `${API_TASK}/generate/brand/tone`,
  'description': `${API_TASK}/generate/brand/description`,
  'tagline': `${API_TASK}/generate/brand/tagline`,
  'content_themes': `${API_TASK}/generate/brand/content_themes`,
  'mission_statement': `${API_TASK}/generate/brand/mission_statement`,
  'ip_guidelines': `${API_TASK}/generate/brand/ip_guidelines`,
  'sensitive_topics': `${API_TASK}/generate/brand/sensitive_topics`,
  'disclaimers': `${API_TASK}/generate/brand/disclaimers`
}

export const GENRATE_PROFILE = {
  'target_audience': `${API_TASK}/generate/social_profile/target_audience`,
  'tone': `${API_TASK}/generate/social_profile/tone`,
  'style': `${API_TASK}/generate/social_profile/style`,
  'preferred_topics': `${API_TASK}/generate/social_profile/preferred_topics`,
  'avoided_topics': `${API_TASK}/generate/social_profile/avoided_topics`,
  'phrases': `${API_TASK}/generate/social_profile/phrases`,
}

export const GENRATE_POST = {
  'cta': `${API_TASK}/generate/social_post/cta`,
  'hashtags': `${API_TASK}/generate/social_post/hashtags`,
  'post': `${API_TASK}/generate/social_post/post`,
  'regenerate_section': `${API_TASK}/generate/social_post/regenerate_section`,
  'expand_section': `${API_TASK}/generate/social_post/expand_section`,
  'contract_section': `${API_TASK}/generate/social_post/contract_section`,
  'chat': `${API_TASK}/generate/social_post/chat`,
}
